.image-container {
    position: relative;
}
.image-container img {
    object-fit: cover;
}

.image-container .hover-content {
    width: 0px;
    height: 0px;
    visibility: hidden;
    z-index: 1;
    position: absolute;
    overflow: hidden;
    left: 10px;
    top: 10px;
    padding: 10px;
    background: rgb(70, 130, 180);
    opacity: 20%;
    border-radius: 20px;
}